import React from "react";
import {
  Teste,
  BoxDates,
  BoxLines,
  CellDate,
  Container,
  HeaderDates,
  HorasTotais,
} from "./styles";
import Acordeon from "../accordeon";
import { useTimes } from "@/hooks/useTime";
import { useSheet } from "@/hooks/useSheet";
import AccordeonSimple from "../accordeonSimple";
import { decimalParaHoraMinuto } from "@/utils/date";
import { Alert, Button, Dialog, DialogActions, Slide } from "@mui/material";

export default function Table() {
  const { currentsWeek } = useSheet();
  const { handleGetSheetUser, sheetData, totals, totalHoras } = useTimes();

  React.useEffect(() => {
    handleGetSheetUser();
  }, []);

  const somaHorasTotais = React.useMemo(() => {
    return totals.reduce((acc: any, total: any) => acc + total?.total || 0, 0);
  }, [totals]);

  return (
    <Container>
      <HeaderDates>
        <Teste />
        <BoxDates>
          {currentsWeek?.map((week: any, index: any) => (
            <CellDate key={index}>
              <p>{index + 1} Semana</p>
              <p>
                {week.at(0).dia}/{week.at(0).mes} a {week.at(-1).dia}/
                {week.at(0).mes}
              </p>
            </CellDate>
          ))}
        </BoxDates>
      </HeaderDates>
      <BoxLines>
        {sheetData?.map((category: any, index: any) => (
          <AccordeonSimple
            key={index}
            title={category.type?.name}
            arrow={category.projects.length > 0}
          >
            {category.projects.map((data: any, dataIndex: any) => (
              <Acordeon data={data} key={dataIndex} hours={category} />
            ))}
          </AccordeonSimple>
        ))}
      </BoxLines>
      <HeaderDates>
        <HorasTotais>Total Mês: {decimalParaHoraMinuto(somaHorasTotais)}</HorasTotais>
        <BoxDates>
          {totals.slice(0, currentsWeek.length)?.map((total: any, index: any) => (
            <CellDate key={index}>
              <p>{decimalParaHoraMinuto(total?.total)}</p>
            </CellDate>
          ))}
        </BoxDates>
      </HeaderDates>
      <AlertDialog />
    </Container>
  );
}

export const AlertDialog = () => {
  const { openAlert, setOpenAlert } = useTimes();

  return (
    <Dialog color="error" open={openAlert} onClose={() => setOpenAlert(false)} TransitionComponent={Transition}>
      <Alert severity="error">
        <p style={{ fontSize: '18px' }}>
        Atenção: Você ultrapassou o limite mensal de <b>192 horas </b> para alocação aos projetos.
        </p>
        <p style={{ fontSize: '18px' }}>
          Verifique seu registro de horas para evitar inconsistências.
        </p>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="error">Fechar</Button>
        </DialogActions>
      </Alert>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props: any & {
  children?: React.ReactElement;
}, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
}
);